import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "島根県松江市でお試し勤務してきました",
  "date": "2017-05-23T08:18:59.000Z",
  "slug": "entry/2017/05/23/171859",
  "tags": ["medley"],
  "hero": "./2017_05_23.png",
  "heroAlt": "matsue"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、`}<a parentName="p" {...{
        "href": "https://toppa.medley.jp/"
      }}>{`平山`}</a>{`です。しれっと公式ブログ初登場です。`}</p>
    <p>{`5 月 10 日から 5 月 13 日まで、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C1%ED%CC%B3%BE%CA"
      }}>{`総務省`}</a>{`の`}<a parentName="p" {...{
        "href": "https://www.otameshi-satellite-office.jp/"
      }}>{`「お試しサテライトオフィス」事業`}</a>{`を利用して`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%E7%BA%AC%B8%A9"
      }}>{`島根県`}</a><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`で 3 泊 4 日のお試し勤務をしてきました。そうです、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の聖地といわれるあの`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`です。`}</p>
    <p>{`オンライン診療アプリ CLINICS が`}<a parentName="p" {...{
        "href": "https://rubybiz.jp/"
      }}>{`Ruby biz グランプリ`}</a>{`で受賞したことをきっかけに、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`から`}<a parentName="p" {...{
        "href": "https://www1.city.matsue.shimane.jp/jigyousha/sangyou/kigyou/otameshi.html"
      }}>{`お試しサテライトオフィスの提案`}</a>{`をうけていましたが、ちょうど別件で松江に行く用事ができたので、せっかくならばお試し勤務をしてみようということで開発本部の 3 名で松江まで行ってきました。`}</p>
    <h1>{`DAY1 - 松江テルサ別館でお試し勤務`}</h1>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B1%A9%C5%C4%B6%F5%B9%C1"
      }}>{`羽田空港`}</a>{`から`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%B6%F5%B9%C1"
      }}>{`出雲空港`}</a>{`まで 1 時間半のフライト、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%B6%F5%B9%C1"
      }}>{`出雲空港`}</a>{`から`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`街まで 40 分の空港連絡バスでの移動、東京から約 2-3 時間で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`に到着しました。思ったより近いんですね。`}</p>
    <p>{`1 日目は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%B1%D8"
      }}>{`松江駅`}</a>{`前にある「松江テルサ別館」でのお試し勤務。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171143.jpg",
        "alt": "f:id:medley_inc:20170516171143j:plain",
        "title": "f:id:medley_inc:20170516171143j:plain"
      }}></img></p>
    <p>{`お試しオフィスの隣にはエンジニアの交流拠点「`}<a parentName="p" {...{
        "href": "https://www1.city.matsue.shimane.jp/jigyousha/sangyou/ruby/rabo_open.html"
      }}>{`松江オープンソースラボ`}</a>{`」があり、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`の神さま Matz さん（の看板）が我々を迎えてくれました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171155.jpg",
        "alt": "f:id:medley_inc:20170516171155j:plain",
        "title": "f:id:medley_inc:20170516171155j:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%AA%A1%BC%A5%D7%A5%F3%A5%BD%A1%BC%A5%B9"
      }}>{`オープンソース`}</a>{`ラボの自販機の売上の一部は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`アソシエーションの支援金になるようです。さすが`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{` City MATSUE ですね。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171309.jpg",
        "alt": "f:id:medley_inc:20170516171309j:plain",
        "title": "f:id:medley_inc:20170516171309j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171253.jpg",
        "alt": "f:id:medley_inc:20170516171253j:plain",
        "title": "f:id:medley_inc:20170516171253j:plain"
      }}></img></p>
    <p>{`お昼は「`}<a parentName="p" {...{
        "href": "https://www.yakumoan.jp/"
      }}>{`八雲庵`}</a>{`」で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%A4%BD%A4%D0"
      }}>{`出雲そば`}</a>{` 。独特の食べ方で食べるそばはとてもおいしく、また庭の池には立派な鯉が泳いでいたりとたいへん風情のあるお店でした。そばを食べている時に池の鯉がどこかから飛んできた鷹に連れ去られるのを見て、松江はすごいところだなと思いました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171325.jpg",
        "alt": "f:id:medley_inc:20170516171325j:plain",
        "title": "f:id:medley_inc:20170516171325j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171338.jpg",
        "alt": "f:id:medley_inc:20170516171338j:plain",
        "title": "f:id:medley_inc:20170516171338j:plain"
      }}></img></p>
    <p>{`お昼のあとはお試し勤務を本格的に開始（上: お試し勤務前の記念写真）。机、椅子、スタンディングデスク、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Bluetooth"
      }}>{`Bluetooth`}</a>{`スピーカー、ビデオ会議用カメラなど、オフィス設備はいずれも本格的なものが用意されていて、仕事をするのにまったく不自由がない環境でした（下：せっかくなので本社にいるメンバーとビデオ会議）。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516123923.jpg",
        "alt": "f:id:medley_inc:20170516123923j:plain",
        "title": "f:id:medley_inc:20170516123923j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516123922.jpg",
        "alt": "f:id:medley_inc:20170516123922j:plain",
        "title": "f:id:medley_inc:20170516123922j:plain"
      }}></img></p>
    <p>{`1 日目は移動で疲れたこともあり、仕事を早めに切り上げ「`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000087/"
      }}>{`根っこや`}</a>{`」で夜ご飯＆飲み。島根の地酒の「`}<a parentName="p" {...{
        "href": "https://www.ouroku.com/"
      }}>{`王祿の渓（にごり）`}</a>{`」は色々な飲み方が楽しめ、美味しくいただきました。`}</p>
    <h1>{`DAY2 - 古民家風オフィスでお試し勤務`}</h1>
    <p>{`2 日目は国宝`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BE%EB"
      }}>{`松江城`}</a>{`近くに建つ趣のある「古民家風オフィス」でのお試し勤務。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172409.jpg",
        "alt": "f:id:medley_inc:20170516172409j:plain",
        "title": "f:id:medley_inc:20170516172409j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518224844.jpg",
        "alt": "f:id:medley_inc:20170518224844j:plain",
        "title": "f:id:medley_inc:20170518224844j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516124207.jpg",
        "alt": "f:id:medley_inc:20170516124207j:plain",
        "title": "f:id:medley_inc:20170516124207j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518224938.jpg",
        "alt": "f:id:medley_inc:20170518224938j:plain",
        "title": "f:id:medley_inc:20170518224938j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518225051.jpg",
        "alt": "f:id:medley_inc:20170518225051j:plain",
        "title": "f:id:medley_inc:20170518225051j:plain"
      }}></img></p>
    <p>{`オフィスの設備は 1 日目と同じものが用意され、加えて今回はひとりひとりにデスクがあり、とても快適に仕事をすることができました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516124312.jpg",
        "alt": "f:id:medley_inc:20170516124312j:plain",
        "title": "f:id:medley_inc:20170516124312j:plain"
      }}></img></p>
    <p>{`この日のお昼は「`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000219/"
      }}>{`西洋軒`}</a>{`」で洋食を食べました。古い佇まいながらも小綺麗なお店でポークカツレツがおいしかったです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516171916.jpg",
        "alt": "f:id:medley_inc:20170516171916j:plain",
        "title": "f:id:medley_inc:20170516171916j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172314.jpg",
        "alt": "f:id:medley_inc:20170516172314j:plain",
        "title": "f:id:medley_inc:20170516172314j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172542.jpg",
        "alt": "f:id:medley_inc:20170516172542j:plain",
        "title": "f:id:medley_inc:20170516172542j:plain"
      }}></img></p>
    <p>{`また、せっかく`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BE%EB"
      }}>{`松江城`}</a>{`の近くにきたということで、休憩時間を利用して`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BE%EB"
      }}>{`松江城`}</a>{`を散策しました。国宝`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BE%EB"
      }}>{`松江城`}</a>{`はとても立派でした（下: メドレーの人文字をやろうとしてイタい感じになっているおじさんたちの図）。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172646.jpg",
        "alt": "f:id:medley_inc:20170516172646j:plain",
        "title": "f:id:medley_inc:20170516172646j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518225152.jpg",
        "alt": "f:id:medley_inc:20170518225152j:plain",
        "title": "f:id:medley_inc:20170518225152j:plain"
      }}></img></p>
    <p>{`仕事が終わった後には、オフィス近くにある「`}<a parentName="p" {...{
        "href": "https://www.facebook.com/otsumami.lab2016/"
      }}>{`おつまみ研究所松江殿町ラボ`}</a>{`」で軽く立ち飲み。地元クリエイターが内装やパッケージのデザインをしているようで、クリエイティブ感満載の居心地のよいお店でした。立ち飲みでは飲み足りないということで、2 日目はこのまま夜の街に繰り出しました。`}</p>
    <h1>{`DAY3 - ゆめっくす北陵でお試し勤務`}</h1>
    <p>{`3 日目は市街地から少し離れた「ゆめっくす北陵」でお試し勤務。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172839.jpg",
        "alt": "f:id:medley_inc:20170516172839j:plain",
        "title": "f:id:medley_inc:20170516172839j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172830.jpg",
        "alt": "f:id:medley_inc:20170516172830j:plain",
        "title": "f:id:medley_inc:20170516172830j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172820.jpg",
        "alt": "f:id:medley_inc:20170516172820j:plain",
        "title": "f:id:medley_inc:20170516172820j:plain"
      }}></img></p>
    <p>{`ゆめっくす北陵は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`街地から少し離れた「`}<a parentName="p" {...{
        "href": "https://www.techno-arc-shimane.jp/"
      }}>{`テクノアークしまね`}</a>{`」という研究開発施設があつまる場所の近くにあります。緑に囲まれた静かな環境にあり開発に専念できる環境です。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518225634.jpg",
        "alt": "f:id:medley_inc:20170518225634j:plain",
        "title": "f:id:medley_inc:20170518225634j:plain"
      }}></img></p>
    <p>{`こちらでも 1 日目、2 日目と同様に充実したオフィス設備が用意されていました。今までのオフィスと比較して市街地から離れた場所にあったということもあり、とても静かな環境でよりいっそう仕事に集中することができました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516172857.jpg",
        "alt": "f:id:medley_inc:20170516172857j:plain",
        "title": "f:id:medley_inc:20170516172857j:plain"
      }}></img></p>
    <p>{`お昼ごはんは「`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000105/"
      }}>{`お食事処ふの`}</a>{`」で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`民の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%BD%A5%A6%A5%EB%A5%D5%A1%BC%A5%C9"
      }}>{`ソウルフード`}</a>{`といわれているカツライス。ボリュームが多いわりにさっぱりと食べられました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516173018.jpg",
        "alt": "f:id:medley_inc:20170516173018j:plain",
        "title": "f:id:medley_inc:20170516173018j:plain"
      }}></img></p>
    <p>{`その後は松江出張の目的でもあった打ち合わせと会食を無事に終わらせ、松江のバー「`}<a parentName="p" {...{
        "href": "https://tabelog.com/shimane/A3201/A320101/32000006/"
      }}>{`大正倶楽部`}</a>{`」でしっぽりと飲んで最後の夜を楽しみました。`}</p>
    <h1>{`DAY4 - プロジェクト成功祈願`}</h1>
    <p>{`松江お試し勤務の最終日は土曜日ということもあり、業務はせずにプロジェクトの成功祈願をしてきました。`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%E7%BA%AC%C8%BE%C5%E7"
      }}>{`島根半島`}</a>{`の東と西に位置する`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C8%FE%CA%DD%BF%C0%BC%D2"
      }}>{`美保神社`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%C2%E7%BC%D2"
      }}>{`出雲大社`}</a>{`の両方にお参りすることを「`}<a parentName="p" {...{
        "href": "https://www.mihonoseki-kankou.jp/ryoumairi/"
      }}>{`えびすだいこく両参り`}</a>{`」といい、両参りをすることで願いが成就すると言われているようです。また、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%C2%E7%BC%D2"
      }}>{`出雲大社`}</a>{`の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BC%E7%BA%D7%BF%C0"
      }}>{`主祭神`}</a><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%AA%A5%AA%A5%AF%A5%CB%A5%CC%A5%B7"
      }}>{`オオクニヌシ`}</a>{`は医療の神様ともいわれ、医療 IT`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D9%A5%F3%A5%C1%A5%E3%A1%BC"
      }}>{`ベンチャー`}</a>{`であるメドレーが祈願をしない理由はありません。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516130322.jpg",
        "alt": "f:id:medley_inc:20170516130322j:plain",
        "title": "f:id:medley_inc:20170516130322j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516173129.jpg",
        "alt": "f:id:medley_inc:20170516173129j:plain",
        "title": "f:id:medley_inc:20170516173129j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518225924.jpg",
        "alt": "f:id:medley_inc:20170518225924j:plain",
        "title": "f:id:medley_inc:20170518225924j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230028.jpg",
        "alt": "f:id:medley_inc:20170518230028j:plain",
        "title": "f:id:medley_inc:20170518230028j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230044.jpg",
        "alt": "f:id:medley_inc:20170518230044j:plain",
        "title": "f:id:medley_inc:20170518230044j:plain"
      }}></img></p>
    <p>{`まずは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C8%FE%CA%DD%BF%C0%BC%D2"
      }}>{`美保神社`}</a>{`での参拝。巫女舞を近くでみるのは初めてで貴重な体験でしたが、厳かな雰囲気の中で商売繁盛とプロジェクトの成功を祈願しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516173505.jpg",
        "alt": "f:id:medley_inc:20170516173505j:plain",
        "title": "f:id:medley_inc:20170516173505j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230223.jpg",
        "alt": "f:id:medley_inc:20170518230223j:plain",
        "title": "f:id:medley_inc:20170518230223j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516133037.jpg",
        "alt": "f:id:medley_inc:20170516133037j:plain",
        "title": "f:id:medley_inc:20170516133037j:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C8%FE%CA%DD%BF%C0%BC%D2"
      }}>{`美保神社`}</a>{`から`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%C2%E7%BC%D2"
      }}>{`出雲大社`}</a>{`への移動の途中では「`}<a parentName="p" {...{
        "href": "https://www.facebook.com/kairaku.matsue/"
      }}>{`SUP (Stand Up Paddle)`}</a>{`」というアクティビティを体験しました。SUP は大きめのサーフボードのようなものに立って乗りパドルで漕ぐというウォータースポーツで、最近流行ってきているようです。当然のように室内で過ごすことが得意な我々にとって、ウォータースポーツはなじみの無いものでしたが、初心者でも丁寧にレクチャーしてくれて存分に楽しむことができました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170516/20170516133426.jpg",
        "alt": "f:id:medley_inc:20170516133426j:plain",
        "title": "f:id:medley_inc:20170516133426j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230357.jpg",
        "alt": "f:id:medley_inc:20170518230357j:plain",
        "title": "f:id:medley_inc:20170518230357j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230532.jpg",
        "alt": "f:id:medley_inc:20170518230532j:plain",
        "title": "f:id:medley_inc:20170518230532j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170518/20170518230442.jpg",
        "alt": "f:id:medley_inc:20170518230442j:plain",
        "title": "f:id:medley_inc:20170518230442j:plain"
      }}></img></p>
    <p>{`そして`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BD%D0%B1%C0%C2%E7%BC%D2"
      }}>{`出雲大社`}</a>{`に到着。最近メディアで取り上げられることが多く、縁結びの神様がいるということで女性観光客が多く来ていました。建築物の歴史の深さと職人のこだわりを感じながら、こちらでもプロジェクトの成功を祈願し、無事に両参りを終えることができました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`以上、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`でのお試し勤務の様子でした。落ち着いた環境で勤務ができたことで、仕事もはかどり良い機会となりました 。島根に対しては、東京から遠そう、市内の交通の便が悪そう、ネットワークがつながらなさそう、曇りが多そう、というネガティブな印象を失礼ながら持っていましたが、インターネットの恩恵を受けることできる環境は十分にあるので、エンジニアとして仕事をする分には大きく困ることは無いように思いました。`}</p>
    <p>{`また、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%BE%B9%BE%BB%D4"
      }}>{`松江市`}</a>{`には`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BE%F0%CA%F3%B9%A9%B3%D8"
      }}>{`情報工学`}</a>{`の専門課程がある大学や`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B9%E2%C0%EC"
      }}>{`高専`}</a>{`があったり、街をあげて`}<a parentName="p" {...{
        "href": "https://smalruby.jp/"
      }}>{`IT エンジニアの教育`}</a>{`に力をいれていたりと、教育の機会が十分に提供されつつあり、エンジニア採用という観点からも、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B5%A5%C6%A5%E9%A5%A4%A5%C8%A5%AA%A5%D5%A5%A3%A5%B9"
      }}>{`サテライトオフィス`}</a>{`の選択肢は十分検討する価値はあるなと思いました。`}</p>
    <p>{`オフラインで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%E1%A5%EA%A5%CF%A5%EA"
      }}>{`メリハリ`}</a>{`を付けて効率的にチームで仕事をしていきたいというのが自分の基本スタンスなのですが、今回のお試し勤務をふまえて、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B5%A5%C6%A5%E9%A5%A4%A5%C8%A5%AA%A5%D5%A5%A3%A5%B9"
      }}>{`サテライトオフィス`}</a>{`を前提とした現実的で効率的な働き方というものも模索していきたいと思います。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回のお試し勤務は`}<a parentName="p" {...{
        "href": "https://fledge.jp/article/shimaneken-matsue"
      }}>{`松江市役所の福田さん`}</a>{`のコーディネートのもと実現しました。ホスピタリティと仕事力がとても高い方で、福田さんのおかげで今回のお試し勤務を不自由なく終えることができました。IT 企業誘致や UI ターン支援などを担当されている方なので、興味のある方は福田さんに連絡してみるとよいと思います（6 月 9 日にはお試し`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B5%A5%C6%A5%E9%A5%A4%A5%C8%A5%AA%A5%D5%A5%A3%A5%B9"
      }}>{`サテライトオフィス`}</a>{`の`}<a parentName="p" {...{
        "href": "https://lig.connpass.com/event/56767/"
      }}>{`説明会`}</a>{`もあるようです）。もちろん`}<a parentName="p" {...{
        "href": "https://www.facebook.com/sosuke.hirayama"
      }}>{`平山宛`}</a>{`に連絡いだければお繋げします。`}</p>
    <p>{`福田さんをはじめ今回お世話になった方々、本当にありがとうございました！`}</p>
    <p>{`ということで、メドレーは今回のお試し勤務のように働き方についても議論できるフェーズです。そんな初期フェーズの会社で泥臭く一緒にコミットしていける人を絶賛募集しています。興味のあるエンジニア・デザイナーの方はご連絡ください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      